<template>
 <div class="bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
  <div class="px-4 mx-auto max-w-7xl 2xl:px-0">
   <form @submit.prevent="validateForm" method="POST">
    <div class="space-y-10">
     <div class="pt-5 pb-12 border-b border-gray-200">
      <div class="grid grid-cols-1 mt-5 gap-x-6 gap-y-8 sm:grid-cols-6">
       <div class="sm:col-span-3">
        <label
         for="name"
         class="block text-sm font-semibold leading-6 text-gray-900">
         {{ $t("Nom de la règle entrante*") }}
        </label>
        <div class="mt-2">
         <input
          required
          v-model="this.form.name"
          type="text"
          name="name"
          id="name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
         <span v-if="errors.name" class="text-red-600">{{ errors.name }}</span>
        </div>
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="host_name"
         class="block text-sm font-semibold leading-6 text-gray-900">
         {{ $t("PBX") }}*
        </label>
        <div class="mt-2">
         <select
          required
          v-model="form.host_name"
          min="0"
          name="host_name"
          id="host_name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6">
          <option
           v-for="(item, index) in hostsNameOptions"
           :key="index"
           :value="item">
           {{ item }}
          </option>
         </select>
         <span v-if="errors.host_name" class="text-red-600">{{
          errors.host_name
         }}</span>
        </div>
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="type"
         class="block text-sm font-semibold leading-6 text-gray-900">
         {{ $t("Type de resource attachée à la règle entrante") }}*
        </label>
        <div class="mt-2">
         <select
          required
          v-model="this.type"
          min="0"
          name="type"
          id="type"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6">
          <option value="queue">{{ $t("queue") }}</option>
          <option value="did">{{ $t("did") }}</option>
         </select>
         <span v-if="errors.type" class="text-red-600">{{ errors.type }}</span>
        </div>
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="number"
         class="block text-sm font-semibold leading-6 text-gray-900">
         {{ $t("Ressource attachée à la règle entrante") }}*
        </label>
        <div class="w-full">
         <div class="relative">
          <!-- <div
           class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
           <SearchIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
           class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
           <SearchIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
          </div> -->
          <div class="mt-2">
           <vue-select
            v-if="this.type !== 'queue'"
            v-model="searchNumber"
            :options="selectOptionsNumbers"
            label-by="label"
            :close-on-select="true"
            :disabled="selectOptionsNumbers.length <= 0"
            searchable
            clear-on-select
            :multiple="false"
            :placeholder="
             searchNumber?.label ? searchNumber?.label : $t('search')
            "
            class="block py-1 pl-2 pr-3 text-left placeholder-gray-500 bg-white rounded-md focus:text-gray-900 focus:placeholder-gray-400">
           </vue-select>
           <div v-else>
            <input
             v-model="form.number"
             type="text"
             name="number"
             id="number"
             :class="'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6'" />
           </div>
           <span v-if="errors.number" class="text-red-600">{{
            errors.number
           }}</span>
          </div>
         </div>
        </div>
        <!-- <div class="mt-2">
         <input
          required
          v-model="this.form.number"
          min="0"
          type="number"
          name="number"
          id="number"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
        </div> -->
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="default_destination_type"
         class="block text-sm font-medium leading-6 text-gray-900">
         {{
          $t("Type de destination par défaut en dehors des heures d'ouverture")
         }}
        </label>
        <div class="mt-2">
         <select
          v-model="destinationType"
          min="0"
          name="default_destination_type"
          id="default_destination_type"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6">
          <option
           v-for="type in dest_type"
           :key="type.value"
           :value="type.value">
           {{ $t(type.name) }}
          </option>
         </select>
         <span v-if="errors.destinationType" class="text-red-600">{{
          errors.destinationType
         }}</span>
        </div>
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="default_destination"
         class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("Destination par défaut en dehors des heures d'ouverture") }}
        </label>
        <div class="w-full">
         <div class="relative">
          <!-- <div
           class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
           <SearchIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
           class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
           <SearchIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
          </div> -->
          <div class="mt-2">
           <vue-select
            v-if="
             !destinationType ||
             destinationType === 'extension' ||
             destinationType === 'call_queue' ||
             destinationType === 'other' ||
             destinationType === 'voicemail'
            "
            v-model="searchDestNumber"
            :options="selectOptions"
            label-by="label"
            :close-on-select="true"
            :disabled="selectOptions.length <= 0"
            searchable
            clear-on-select
            :multiple="false"
            :placeholder="
             searchDestNumber?.label ? searchDestNumber?.label : $t('search')
            "
            class="block py-1 pl-2 pr-3 text-left placeholder-gray-500 bg-white rounded-md focus:text-gray-900 focus:placeholder-gray-400">
           </vue-select>
           <span v-if="errors.default_destination" class="text-red-600">{{
            errors.default_destination
           }}</span>
           <div v-else-if="destinationType === 'external_number'">
            <input
             v-model="searchDestNumber"
             type="text"
             name="default_destination"
             id="default_destination"
             :class="
              ({ 'is-invalid': phoneError },
              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6')
             " />
            <span v-if="phoneError" class="error">{{ phoneError }}</span>
            <span v-if="errors.default_destination" class="text-red-600">{{
             errors.default_destination
            }}</span>
           </div>
           <div v-else-if="destinationType === 'end_call'">
            <input
             v-model="searchDestNumber"
             type="text"
             name="default_destination"
             id="default_destination"
             disabled=""
             class="block w-full bg-gray-100 cursor-not-allowed rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
           </div>
          </div>
         </div>
        </div>
        <!-- <div class="mt-2">
         <input
          v-model="destination"
          min="0"
          type="number"
          name="default_destination"
          id="default_destination"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
        </div> -->
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="callQueueGroupId"
         class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("inbound rules groups") }}
        </label>
        <div class="mt-2">
         <select
          v-model="this.form.call_queue_group_id"
          type="number"
          id="callQueueGroupId"
          name="callQueueGroupId"
          class="block w-full border-gray-300 rounded shadow-sm focus:encom_border_input sm:text-sm">
          <option value="">{{ $t("none") }}</option>
          <option
           v-for="callQueueGroup in this.callQueuesGroups"
           :key="callQueueGroup.id"
           :value="callQueueGroup.id">
           {{ callQueueGroup.name }}
          </option>
         </select>
        </div>
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="code"
         class="block text-sm font-medium leading-6 text-gray-900">
         Code
        </label>
        <div class="mt-2">
         <input
          v-model="this.form.code"
          type="text"
          name="code"
          id="code"
          class="block w-full border-gray-300 rounded shadow-sm focus:encom_border_input sm:text-sm" />
         <!-- <span v-if="errors.code" class="text-red-600">{{ errors.code }}</span> -->
        </div>
       </div>
       <div
        class="flex items-center justify-start col-span-6 gap-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="active"
         class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("active") }}
        </label>
        <div class="">
         <input
          v-model="this.form.active"
          type="checkbox"
          name="active"
          id="active"
          class="block border-gray-300 rounded shadow-sm focus:encom_border_input sm:text-sm" />
        </div>
       </div>
      </div>
     </div>
    </div>
    <div class="mt-4">
     <span class="text-sm font-medium text-gray-600"
      >*Une fois la règle entrante créée, vous serez redirigé vers un second
      formulaire vous permettant de configurer les plages horaires et les jours
      exceptionnels lui correspondant.</span
     >
    </div>
    <div class="flex items-center justify-end pb-12 mt-6 gap-x-6">
     <button
      type="button"
      @click="this.$emit('closeForm')"
      class="px-3 py-2 text-sm font-semibold text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50">
      {{ $t("ticket.cancel") }}
     </button>
     <button
      type="submit"
      class="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm body__table--header">
      {{ $t("ticket.send") }}
     </button>
    </div>
   </form>
  </div>
 </div>
 <ComfirmationDialog
  v-if="this.showMessage"
  :messageToShow="this.messageToShow"
  :subMessageToShow="this.subMessageToShow" />
</template>

<script>
import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";
import axios from "axios";
import { mapGetters } from "vuex";
import ComfirmationDialog from "../components/ConfirmationDialog.vue";
import { SearchIcon } from "@heroicons/vue/outline";

const dest_type = [
 { name: "extension", value: "extension" },
 { name: "voicemail", value: "voicemail" },
 //  { name: "svi", value: "svi" },
 { name: "queue", value: "call_queue" },
 //  { name: "call flow", value: "call_flow" },
 { name: "end call", value: "end_call" },
 { name: "external number", value: "external_number" },
 { name: "other", value: "other" },
];

const account = localStorage.getItem("account");
export default {
 name: "CreateNewCallQueue",
 props: [
  "title",
  "subTitle",
  "callQueuesGroups",
  "closeFormButton",
  "editData",
  "openUpdateForm",
  "callQueuesOptions",
  "hostsNameOptions",
  "hostsOptions",
  "hostName",
 ],
 components: {
  ComfirmationDialog,
  SearchIcon,
  VueSelect,
 },
 data() {
  return {
   phoneError: null,
   searchDestNumber: "",
   destNumber: null,
   searchNumber: "",
   account,
   dest_type,
   selectOptions: [],
   selectOptionsNumbers: [],
   closeForm: true,
   destinationType: null,
   destination: null,
   type: "queue",
   form: {
    destination: null,
    number: "",
    name: "",
    code: "",
    call_queue_group_id: null,
    active: true,
    host_name: "",
   },
   showMessage: false,
   messageToShow: "",
   subMessageToShow: "",
   searchableTerm: "",
   extensionOptions: [],
   othersOptions: [],
   queueOptions: [],
   didOptions: [],
   optionOnSelectHost: {},
   errors: {
    destination: null,
    number: "",
    name: "",
    code: "",
    host_name: "",
   },
  };
 },
 methods: {
  validateForm() {
   // Reset errors
   this.errors = {};
   if (this.title === this.$t("callqueue.newFormTitle")) {
    // Validate required fields
    if (!this.form.name) {
     this.errors.name = "Veuillez compléter ce champ";
    }
    if (!this.form.host_name) {
     this.errors.host_name = "Veuillez compléter ce champ";
    }
    if (!this.type) {
     this.errors.type = "Veuillez compléter ce champ";
    }
    if (!this.searchNumber && !this.form.number) {
     this.errors.number = "Veuillez compléter ce champ";
    }
    //  if (!this.destinationType) {
    //   this.errors.destinationType = "Veuillez compléter ce champ";
    //  }
    //  if (!this.searchDestNumber) {
    //   this.errors.default_destination = "Veuillez compléter ce champ";
    //  }
    //  if (!this.form.call_queue_group_id) {
    //   this.errors.call_queue_group_id = "Veuillez compléter ce champ";
    //  }
    //  if (!this.form.code) {
    //   this.errors.code = "Veuillez compléter ce champ";
    //  }
   }
   // If there are no errors, submit the form
   if (Object.keys(this.errors).length === 0) {
    this.openUpdateForm
     ? this.putCallQueue(this.editData.id)
     : this.postCallQueue();
   }
  },
  postCallQueue() {
   if (this.title === this.$t("callqueue.newFormTitle")) {
    let data = {};
    if (this.type === "queue") {
     data = {
      number: this.form.number,
      name: this.form.name,
      code: this.form.code,
      call_queue_group_id: this.form.call_queue_group_id
       ? this.form.call_queue_group_id
       : null,
      type: this.type,
      active: this.form.active ? 1 : 0,
      default_destination: this.form.destination,
      default_destination_type: this.destinationType,
      host_name: this.form.host_name,
     };
    } else {
     data = {
      did_number: this.form.number,
      name: this.form.name,
      code: this.form.code,
      call_queue_group_id: this.form.call_queue_group_id
       ? this.form.call_queue_group_id
       : null,
      type: this.type,
      active: this.form.active ? 1 : 0,
      default_destination: this.form.destination,
      default_destination_type: this.destinationType,
      host_name: this.form.host_name,
     };
    }
    console.log("postCallQueue ~ data:", data);
    axios
     .post(
      `${this.$cookie.getCookie("API")}/api/v1/callQueues?customerAccount=${
       this.account
      }`,
      data,
      {
       headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     )
     .then(res => {
      let msg = this.$t("elementCreatedSuccessfully", {
       element: this.$t("Règle entrante"),
      });
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      this.$emit("closeForm");

      this.cleanForm();
      this.$router.push({
       name: "CallQueuesDetailExpert3cx",
       params: { account: this.account, id: res.data.id },
      });
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   } else {
    const options = {
     method: "POST",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroups`,
     params: {
      customerAccount: this.account,
      name: this.form.name,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };
    axios
     .request(options)
     .then(res => {
      let msg = this.$t("elementCreatedSuccessfully", {
       element: this.$t("Règle entrante"),
      });
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      this.cleanForm();
      this.$emit("closeForm");

      this.$router.push({
       name: "CallQueuesGroupDetailExpert3cx",
       params: { account: this.account, id: res.data.id },
      });
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  putCallQueue(id) {
   if (this.title === this.$t("callqueue.updateFormTitle")) {
    let data = {};
    if (this.type === "queue") {
     data = {
      number: this.form.number,
      did_number: null,
      name: this.form.name,
      code: this.form.code,
      call_queue_group_id: this.form.call_queue_group_id
       ? this.form.call_queue_group_id
       : null,
      type: this.type,
      active: this.form.active ? 1 : 0,
      default_destination: this.form.destination,
      default_destination_type: this.destinationType,
      host_name: this.form.host_name,
     };
    } else {
     data = {
      did_number: this.form.number,
      number: null,
      name: this.form.name,
      code: this.form.code,
      call_queue_group_id: this.form.call_queue_group_id
       ? this.form.call_queue_group_id
       : null,
      type: this.type,
      active: this.form.active ? 1 : 0,
      default_destination: this.form.destination,
      default_destination_type: this.destinationType,
      host_name: this.form.host_name,
     };
    }

    // return console.log("data", data);
    axios
     .put(
      `${this.$cookie.getCookie(
       "API"
      )}/api/v1/callQueues/${id}?customerAccount=${this.account}`,
      data,
      {
       headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     )
     .then(res => {
      let msg = this.$t("elementUpdatedSuccessfully", {
       element: this.$t("Règle entrante"),
      });
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      this.$emit("closeForm");
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   } else {
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroups/${id}`,
     params: {
      customerAccount: this.account,
      name: this.form.name,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };

    axios
     .request(options)
     .then(res => {
      let msg = this.$t("elementUpdatedSuccessfully", {
       element: this.$t("callqueueGroup"),
      });
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      this.$emit("closeForm");
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
  cleanForm() {
   this.form.number = "";
   this.form.name = "";
   this.form.call_queue_group_id = null;
   this.form.code = "";
  },
  fillDest() {
   let data =
    Object.keys(this.optionOnSelectHost).length > 0
     ? this.optionOnSelectHost
     : this.callQueuesOptions;
   Object.keys(data?.extensions).forEach(val => {
    let obj = {};
    obj["value"] = val;
    obj["label"] = data?.extensions[val];
    this.extensionOptions.push(obj);
   });
   Object.keys(data?.queues).forEach(val => {
    let objQueue = {};
    objQueue["value"] = val;
    objQueue["label"] = data?.queues[val];
    this.queueOptions.push(objQueue);
   });
   Object.keys(data?.others).forEach(val => {
    let objOther = {};
    objOther["value"] = val;
    objOther["label"] = data?.others[val];
    this.othersOptions.push(objOther);
   });
  },
  fillOptions() {
   let queues = [];
   let dids = [];
   let data =
    Object.keys(this.optionOnSelectHost).length > 0
     ? this.optionOnSelectHost
     : this.callQueuesOptions;
   if (data && Object.keys(data).length > 0) {
    Object.keys(data?.queues).forEach(val => {
     let obj = {};
     obj["value"] = val;
     obj["label"] = data?.queues[val];
     queues.push(obj);
    });
    Object.keys(data?.dids).forEach(val => {
     let obj = {};
     if (data?.used_dids.indexOf(val) < 0) {
      obj["value"] = val;
      obj["label"] = data?.dids[val];
      dids.push(obj);
     }
     if (this.openUpdateForm && this.editData && this.editData?.did_number) {
      if (val == this.editData?.did_number) {
       obj["value"] = val;
       obj["label"] = data?.dids[val];
       dids.push(obj);
      }
     }
    });
    switch (this.type) {
     case "did":
      this.selectOptionsNumbers = dids;
      if (this.openUpdateForm && this.editData) {
       if (this.selectOptionsNumbers?.length) {
        let filteredArrDid = this.selectOptionsNumbers?.filter(
         res => res.value == this.editData?.did_number
        );
        this.searchNumber = filteredArrDid.length > 0 ? filteredArrDid[0] : "";
       }
      }
      break;
     case "queue":
      this.selectOptionsNumbers = queues;
      if (this.openUpdateForm && this.editData) {
       if (this.selectOptionsNumbers?.length) {
        let filteredArr = this.selectOptionsNumbers?.filter(
         res => res.value == this.editData?.number
        );
        this.searchNumber = filteredArr.length > 0 ? filteredArr[0] : "";
       }
      }
      break;

     default:
      console.log("Unknown action");
      break;
    }
   }
  },
 },
 watch: {
  "form.host_name": function (val) {
   if (this.hostsOptions) {
    this.selectOptions = [];
    this.extensionOptions = [];
    this.othersOptions = [];
    this.queueOptions = [];
    this.destinationType = null;
    this.form.default_destination_type = null;
    this.optionOnSelectHost = this.hostsOptions[val];
    this.fillOptions();
    this.fillDest();
   }
   if (this.openUpdateForm && this.editData) {
    this.destinationType = this.editData.default_destination_type;
   }
  },
  type: function (val) {
   this.selectOptionsNumbers = [];
   this.fillOptions();
  },
  searchNumber: function (val) {
   this.form.number = val?.value;
  },
  searchDestNumber: function (val) {
   console.log("searchDestNumber", val);
   if (this.destinationType === "external_number") {
    this.validatePhoneNumber();
    this.destNumber = val;
    this.form.destination = val;
   } else {
    this.destNumber = val?.value;
    this.form.destination = val?.value;
   }
  },
  destinationType: function (val) {
   console.log("destinationType val", val);
   switch (val) {
    case "extension":
     this.selectOptions = this.extensionOptions;
     if (this.openUpdateForm && this.editData) {
      if (this.selectOptions?.length) {
       this.searchDestNumber = this.selectOptions?.filter(
        res => res.value == this.editData?.default_destination
       )[0];
      }
     }
     break;
    case "voicemail":
     this.selectOptions = this.extensionOptions;
     if (this.openUpdateForm && this.editData) {
      if (this.selectOptions?.length) {
       this.searchDestNumber = this.selectOptions?.filter(
        res => res.value == this.editData?.default_destination
       )[0];
      }
     }
     break;
    case "other":
     this.selectOptions = this.othersOptions;
     if (this.openUpdateForm && this.editData) {
      if (this.selectOptions?.length) {
       this.searchDestNumber = this.selectOptions?.filter(
        res => res.value == this.editData?.default_destination
       )[0];
      }
     }
     break;
    case "call_queue":
     this.selectOptions = this.queueOptions;
     if (this.openUpdateForm && this.editData) {
      if (this.selectOptions?.length) {
       this.searchDestNumber = this.selectOptions?.filter(
        res => res.value == this.editData?.default_destination
       )[0];
      }
     }
     break;
    case "call_flow":
     // this.selectOptions = extensions;
     break;
    case "end_call":
     // this.selectOptions = extensions;
     break;
    case "external_number":
     this.searchDestNumber = "";
     if (this.openUpdateForm && this.editData) {
      this.searchDestNumber = this.editData?.default_destination;
     }
     // this.selectOptions = extensions;
     break;
    default:
     console.log("Unknown action");
     break;
   }
  },
 },
 mounted() {
  if (this.callQueuesOptions) {
   this.fillOptions();
   this.fillDest();
  }
  if (this.openUpdateForm && this.editData) {
   console.log("mounted ~ this.editData:", this.editData);
   this.form.number = this.editData.number;
   this.form.name = this.editData.name;
   this.form.code = this.editData.code;
   this.form.host_name = this.editData?.host_name
    ? this.editData?.host_name
    : this.hostName;
   this.type = this.editData.type;
   //  this.default_destination = this.editData.default_destination;
   //  this.default_destination_type = this.editData.default_destination_type;
   this.form.call_queue_group_id = this.editData.call_queue_group_id
    ? this.editData.call_queue_group_id
    : null;
   console.log("this.destinationType", this.destinationType);
   //  this.searchDestNumber = this.editData.default_destination;
   //  this.destNumber = this.editData.default_destination;
   //  this.searchNumber = this.editData.number;
  } else {
   this.form.host_name = this.hostName;
  }
 },
 computed: {
  isFormVisible() {
   return (
    this.title === this.$t("callqueue.newFormTitle") ||
    this.title === this.$t("callqueue.updateFormTitle")
   );
  },
 },
};
</script>

<style scoped>
.is-invalid {
 border-color: red;
}
.error {
 color: red;
 font-size: 0.875em;
}
</style>
